import React, { useEffect } from "react"
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import Page from "../../components/page";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";

const SinglePage = ( props ) => {

	const { pageContext: { title, seo, uri } } = props;

	useEffect(() => {
	if(props.pageContext.translations[0].uri){
		if(props.pageContext.translations[0].uri !== '/pl/gdzie-nas-znalezc/' &&
			props.pageContext.translations[0].uri !== '/where-to-buy/'
		){
		document.getElementById('lang-switch').href= props.pageContext.translations[0].uri
		} 
	}
	});

	return (
		<Layout>
			{
				!isEmpty( props.pageContext ) ? (
					<>
						<SEO
							title={ title }
							seoData={ seo }
							uri={ uri }
							header={ { siteTitle: ' Shop' } }
							openGraphImage={ getOgImage( seo ) }
						/>
						<Page data={ props.pageContext }/>
					</>
				) : (
					<div>Something went wrong</div>
				)
			}
		</Layout>
	)
};
export default SinglePage;
